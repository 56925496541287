import React, { ReactElement } from "react";

import WhatIsDifferentIcon from "../icons/whatIsDifferent";
import colors from "@styles/variables/bestvaluehomewarrantyVariables.module.scss";

export default function WhatIsDifferent(): ReactElement {
    return (
        <section className=" flex flex-col lg:flex-row  lg:gap-32 gap-20 lg:mt-32 mt-20">
            <div className=" max-w-[578px]   px-2 lg:px-0">
                <h2
                    className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  "
                    style={{ color: colors.iconColor }}
                >
                    How Is a Home Warranty Different From Homeowner's Insurance?
                </h2>
                <p className="mt-5  text-[#232323] lg:text-lg text-base leading-8 ">
                    Unlike homeowner's insurance, which protects you against
                    damage to your property and the house's structure, a home
                    warranty focuses on your appliances and systems. It can be
                    an excellent investment to protect your home, but it doesn't
                    replace homeowner's insurance. If you recently bought a new
                    home, you may already have manufacturer warranties that
                    cover some of your home's systems.
                </p>
            </div>
            <div className="lg:w-[466px] lg:min-w-[466px] lg:h-[300px] w-[300px] h-[200px] mx-auto lg:mx-0">
                <WhatIsDifferentIcon />
            </div>
        </section>
    );
}
