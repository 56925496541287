import React, { ReactElement } from "react";

import WhoHomeWarranty from "../icons/whoHomeWarrantyIcon";
import colors from "@styles/variables/bestvaluehomewarrantyVariables.module.scss";

export default function HowIsHomeWarranty(): ReactElement {
    return (
        <section className=" flex flex-col lg:flex-row  lg:gap-32 gap-20 mt-28 mb-10">
            <div className="order-2 lg:order-1 lg:w-[416px] lg:min-w-[416px] lg:h-[299px] w-[300px] h-[200px]  lg:ml-20 mx-auto lg:mx-0">
                <WhoHomeWarranty />
            </div>
            <div className=" max-w-[578px]   px-2 lg:px-0 order-1 lg:order-2">
                <h2
                    className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  "
                    style={{ color: colors.iconColor }}
                >
                    Who Is a Home Warranty For?
                </h2>
                <p className="mt-5  text-[#232323] lg:text-lg text-base leading-8 ">
                    A home warranty is for anyone who owns a home and wants to
                    protect their household appliances and systems from
                    unexpected breakdowns and repairs. It can be particularly
                    beneficial for homeowners who are not handy with repairs or
                    lack time to handle maintenance issues themselves. Home
                    warranties are also helpful for homeowners who want peace of
                    mind knowing that their home systems are covered in case of
                    unexpected breakdowns.
                </p>
            </div>
        </section>
    );
}
