import React from "react";

function WhoHomeWarranty() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="416"
            height="299"
            fill="none"
            viewBox="0 0 416 299"
        >
            <g clipPath="url(#clip0_8_1994)">
                <path
                    fill="#ECF5FF"
                    fillRule="evenodd"
                    d="M105.761 85.662c13.202 0 25.839 2.424 37.49 6.836v-.02C143.251 41.404 184.705 0 235.839 0c51.135 0 92.589 41.404 92.589 92.478 0 17.196-4.704 33.292-12.892 47.086a80.068 80.068 0 0119.166-2.321c44.056 0 79.771 35.67 79.771 79.676 0 44.005-35.715 79.673-79.771 79.673v.012H114.143c-2.767.217-5.56.33-8.382.33-2.821 0-5.614-.113-8.381-.33h-.034v-.005C42.87 292.313 0 246.806 0 191.297 0 132.954 47.352 85.659 105.761 85.659"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#000446"
                    fillRule="evenodd"
                    d="M10.344 296.481H416v.713H10.344v-.713z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#B2D2FF"
                    fillRule="evenodd"
                    d="M187.595 37.278h98.415a3.905 3.905 0 013.896 3.892v133.464a3.906 3.906 0 01-3.896 3.892h-98.415a3.906 3.906 0 01-3.896-3.892V41.17a3.905 3.905 0 013.896-3.892z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M190.074 43.57h93.746v127.942h-93.746V43.57z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#9CC1F4"
                    d="M198.458 85.626h9.973c.763 0 1.454.311 1.96.814h.002c.503.507.815 1.2.815 1.96v9.96a2.778 2.778 0 01-2.777 2.774h-9.973c-.763.001-1.46-.311-1.962-.813a2.767 2.767 0 01-.815-1.96V88.4c0-.763.312-1.455.817-1.96h-.002a2.763 2.763 0 011.962-.817v.003zm9.973 1.512h-9.973c-.345 0-.663.141-.892.37h-.003a1.259 1.259 0 00-.369.892v9.96c0 .349.142.663.372.892.229.23.547.37.892.37h9.973a1.27 1.27 0 001.266-1.262V88.4c0-.348-.142-.665-.371-.891h-.003a1.248 1.248 0 00-.892-.371z"
                ></path>
                <path
                    fill="#9CC1F4"
                    fillRule="evenodd"
                    d="M218.45 86.141h18.968c.632 0 1.15.518 1.15 1.149 0 .63-.518 1.149-1.15 1.149H218.45c-.632 0-1.15-.518-1.15-1.15 0-.63.518-1.148 1.15-1.148z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#9CC1F4"
                    d="M198.401 106.83h9.808c.748 0 1.429.307 1.927.801h.002c.495.5.802 1.18.802 1.927v9.793c0 .752-.307 1.435-.802 1.927h-.002a2.729 2.729 0 01-1.927.801h-9.808a2.73 2.73 0 01-1.929-.801 2.72 2.72 0 01-.802-1.927v-9.793c0-.75.307-1.43.802-1.927a2.73 2.73 0 011.929-.801zm9.808 1.486h-9.808c-.34 0-.652.139-.876.366a1.23 1.23 0 00-.364.876v9.793c0 .34.139.652.366.876.225.227.537.366.877.366h9.808c.343 0 .652-.139.877-.363a1.26 1.26 0 00.366-.879v-9.793c0-.343-.14-.654-.364-.876h-.002a1.228 1.228 0 00-.877-.366"
                ></path>
                <path
                    fill="#EF8200"
                    fillRule="evenodd"
                    d="M201.898 115.861l5.086-4.737a.986.986 0 011.39.052.983.983 0 01-.049 1.388l-5.836 5.432-.016.011a.987.987 0 01-1.387-.093l-2.811-3.215a.984.984 0 01.093-1.386.983.983 0 011.384.093l2.146 2.455z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#5B94EA"
                    d="M204.214 43.57h63.878v-2.522c0-4.901-4.72-7.567-10.032-7.567h-7.105v-1.17c0-2.282-1.87-4.15-4.155-4.15h-3.017v-.033l.002-2.591c0-4.191-3.435-7.622-7.631-7.622s-7.631 3.43-7.631 7.622v2.627h-3.017c-2.285 0-4.155 1.868-4.155 4.15v1.17h-7.174c-7.621 0-9.963 3.963-9.963 7.567v2.522-.003zm31.94-22.592a3.027 3.027 0 013.028 3.024 3.03 3.03 0 01-6.058 0 3.028 3.028 0 013.03-3.024z"
                ></path>
                <path
                    fill="#9CC1F4"
                    fillRule="evenodd"
                    d="M218.45 92.189h57.51c.634 0 1.15.518 1.15 1.149 0 .63-.516 1.149-1.15 1.149h-57.51c-.634 0-1.15-.518-1.15-1.15 0-.63.516-1.148 1.15-1.148zM218.45 98.237h57.51c.634 0 1.15.518 1.15 1.149s-.516 1.149-1.15 1.149h-57.51c-.634 0-1.15-.518-1.15-1.15 0-.63.516-1.148 1.15-1.148zM218.45 106.954h18.968c.632 0 1.15.517 1.15 1.148 0 .632-.518 1.149-1.15 1.149H218.45c-.632 0-1.15-.517-1.15-1.149 0-.631.518-1.148 1.15-1.148zM218.45 113.002h57.51c.634 0 1.15.518 1.15 1.149s-.516 1.148-1.15 1.148h-57.51c-.634 0-1.15-.517-1.15-1.148 0-.631.516-1.149 1.15-1.149zM218.45 119.052h57.51c.634 0 1.15.518 1.15 1.149s-.516 1.149-1.15 1.149h-57.51c-.634 0-1.15-.518-1.15-1.149s.516-1.149 1.15-1.149z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#EF8200"
                    fillRule="evenodd"
                    d="M201.898 94.948l5.086-4.737a.983.983 0 011.341 1.44l-5.836 5.432-.016.013a.986.986 0 01-1.387-.093l-2.811-3.214a.983.983 0 011.477-1.293l2.146 2.454v-.002z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#FBB44A"
                    fillRule="evenodd"
                    d="M203.304 149.038c4.09 0 7.406 3.312 7.406 7.397 0 4.086-3.316 7.398-7.406 7.398-4.091 0-7.407-3.312-7.407-7.398 0-4.085 3.316-7.397 7.407-7.397z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    d="M206.765 157.78h-1.726a.336.336 0 00-.353.35v1.755a.36.36 0 01-.093.249.34.34 0 01-.258.103h-2.063a.326.326 0 01-.247-.103.335.335 0 01-.103-.249v-1.755a.335.335 0 00-.096-.255.338.338 0 00-.255-.095h-1.713a.351.351 0 01-.263-.103.335.335 0 01-.103-.247v-1.973c0-.109.034-.194.103-.255a.366.366 0 01.263-.096h1.713a.322.322 0 00.255-.103.348.348 0 00.096-.247v-1.77c0-.108.033-.193.103-.255a.343.343 0 01.247-.095h2.063c.109 0 .194.031.258.095.062.065.093.15.093.255v1.77c0 .098.036.18.103.247a.35.35 0 00.25.103h1.726c.108 0 .193.031.255.096a.34.34 0 01.095.255v1.973c0 .098-.03.18-.095.247a.322.322 0 01-.255.103z"
                ></path>
                <path
                    fill="#9CC1F4"
                    fillRule="evenodd"
                    d="M218.45 152.646h12.817c.632 0 1.15.518 1.15 1.149s-.518 1.149-1.15 1.149H218.45c-.632 0-1.15-.518-1.15-1.149s.518-1.149 1.15-1.149zM218.45 158.694h34.751c.634 0 1.15.518 1.15 1.149s-.516 1.149-1.15 1.149H218.45c-.632 0-1.15-.518-1.15-1.149s.518-1.149 1.15-1.149z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#5B94EA"
                    fillRule="evenodd"
                    d="M205.999 53.523h62.193c6.483 0 11.788 5.299 11.788 11.774 0 6.476-5.305 11.774-11.788 11.774h-62.193c-6.486 0-11.791-5.298-11.791-11.774 0-6.475 5.305-11.774 11.791-11.774z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#9CC1F4"
                    d="M198.401 128.122h9.808c.748 0 1.429.306 1.927.801h.002c.495.497.802 1.18.802 1.927v9.793c0 .752-.307 1.435-.802 1.927h-.002a2.729 2.729 0 01-1.927.801h-9.808a2.73 2.73 0 01-1.929-.801 2.72 2.72 0 01-.802-1.927v-9.793c0-.75.307-1.43.802-1.927a2.726 2.726 0 011.929-.801zm9.808 1.486h-9.808c-.34 0-.652.139-.876.366a1.23 1.23 0 00-.364.876v9.793c0 .34.139.652.366.876.225.224.537.366.877.366h9.808c.343 0 .652-.14.877-.364.224-.229.366-.538.366-.878v-9.793c0-.343-.14-.655-.364-.876h-.002a1.228 1.228 0 00-.877-.366"
                ></path>
                <path
                    fill="#EF8200"
                    fillRule="evenodd"
                    d="M201.898 137.153l5.086-4.737a.984.984 0 011.341 1.44l-5.836 5.432-.016.013a.987.987 0 01-1.387-.093l-2.811-3.214a.982.982 0 011.477-1.293l2.146 2.454v-.002z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#9CC1F4"
                    fillRule="evenodd"
                    d="M218.45 128.245h18.968c.632 0 1.15.518 1.15 1.149s-.518 1.149-1.15 1.149H218.45c-.632 0-1.15-.518-1.15-1.149s.518-1.149 1.15-1.149zM218.45 134.294h57.51c.634 0 1.15.517 1.15 1.148 0 .631-.516 1.149-1.15 1.149h-57.51c-.634 0-1.15-.518-1.15-1.149s.516-1.148 1.15-1.148zM218.45 140.342h57.51c.634 0 1.15.517 1.15 1.148 0 .632-.516 1.149-1.15 1.149h-57.51c-.634 0-1.15-.517-1.15-1.149 0-.631.516-1.148 1.15-1.148z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4AF8F"
                    fillRule="evenodd"
                    d="M328.908 287.107l-.694-7.318 7.352.176-.072 8.379-6.586-1.237z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#031542"
                    fillRule="evenodd"
                    d="M328.547 286.489c-2.076 3.727-5.463 7.292-2.819 10.461h13.457c.02-.062.043-.127.064-.199.129-.432.758-.613.338-2.202-.781-2.947-3.072-7.478-3.861-7.712-1.56-.461-3.778.84-7.179-.348z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#0F4884"
                    fillRule="evenodd"
                    d="M306.373 185.692l7.873 31.945 4.021 19.02 8.923 46.324s1.888.86 4.905.685c3.02-.175 4.544-.621 4.544-.621.065-13.677.408-32.069.524-48.603.103-14.541.479-27.469.534-38.496.025-4.773-5.406-18.273-5.339-18.1l-25.985 7.846z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#12143C"
                    fillRule="evenodd"
                    d="M307.905 191.907l2.019 8.189c10.329 1.285 21.121.404 27.752-1.396.01-.93.015-1.85.02-2.754.006-.945-.203-2.233-.541-3.691-9.367-.054-24.662-.157-26.148-.26-.492-.036-1.635-.065-3.102-.088z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4AF8F"
                    fillRule="evenodd"
                    d="M296.423 287.107l.026-7.567 7.35.175-.789 8.629-6.587-1.237z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#031542"
                    fillRule="evenodd"
                    d="M295.936 285.487s-11.541 4.74-14.777 6.324c-2.515 1.229-3.941 3.817-1.875 5.139h25.157c.023-.062.044-.127.065-.199.374-1.241 1.351-10.11-1.264-9.914-1.625.124-3.672.52-7.306-1.35z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#0F4884"
                    fillRule="evenodd"
                    d="M295.804 183.974l-2.615 45.329 2.533 53.652s3.528.76 6.545.587c3.018-.175 2.468-.525 2.468-.525l7.064-50.427s1.599-11.483 5.336-20.434c2.7-6.46 6.011-8.325 5.524-15.834-.508-7.776.147-13.804-.923-16.49-1.073-2.689-6.422-1.61-6.422-1.61l-19.51 5.752z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#12143C"
                    fillRule="evenodd"
                    d="M295.353 191.799l-.31 5.378c6.272 1.896 14.945 3.856 27.412 3.496.273-1.311.322-2.512.203-4.351a105.066 105.066 0 01-.183-4.168c-5.787-.048-10.661-.103-11.468-.159-1.79-.126-12.17-.181-15.654-.196zM310.218 243.882l1.581-11.292s.872-6.259 2.832-13.137l-1.496-10.702-3.108 26.837.191 8.294z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#006ED3"
                    fillRule="evenodd"
                    d="M309.003 144.285c-4.497 9.858-6.181 20.591-19.053 22.508-9.106.252-27.811-7.854-26.581-10.991 1.342-3.411-3.435-6.113 1.798-6.015 6.953-.02 23.976 1.739 25.544 1.628 1.486-.103 6.489-21.58 17.22-25.49 1.083-.698 4.239 13.598 1.072 18.36z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#065FB2"
                    fillRule="evenodd"
                    d="M267.568 160.031c-2.855-1.63-4.603-3.199-4.199-4.229 1.342-3.411-3.435-6.113 1.798-6.015a81.657 81.657 0 013.884.106c.928 1.308 3.391 5.381.608 8.389a10.932 10.932 0 01-2.091 1.749z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#065FB2"
                    fillRule="evenodd"
                    d="M304.253 155.521c-2.708 5.59-6.393 10.097-14.302 11.274-6.241.173-16.993-3.58-22.744-6.97 1.413.461 2.979.971 4.485 1.468 4.513 1.484 15.141 4.155 19.904 1.453 4.763-2.704 6.617-12.619 6.617-12.619s4.336 3.769 6.04 5.397"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M263.367 149.496c-.624-.976-3.146-4.814-4.163-5.237-.515-.216-.544-.018-.626.271-.07.255.013.845.196 1.2.387.742.371 2.262.644 2.638-4.443 1.772-10.24-1.28-10.668 1.205.211.461 2.318 2.553 2.718 3.236 1.269 2.166 2.13 3.096 11.633 3.547 3.216.152 3.154-3.037 2.546-5.219l-2.28-1.638v-.003z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#CE5600"
                    fillRule="evenodd"
                    d="M349.585 202.051s-.178-4.31 1.073-4.822c1.25-.513 6.003-.052 6.003-.052s1.303.307 1.411 1.922a49.55 49.55 0 01.108 2.952h-1.609s.127-2.388-.16-2.72c-.286-.335-4.745-.116-4.745-.116s-.606.013-.634 1.231c-.026 1.218 0 1.605 0 1.605h-1.447z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#EA6200"
                    fillRule="evenodd"
                    d="M342.375 201.319l25.809.613.065 37.703h-25.874v-38.316z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#CE5600"
                    fillRule="evenodd"
                    d="M367.519 201.935h.317l.348 3.297.065 34.403h-25.874v-11.628c14.269 18.397 23.504-9.965 25.144-26.072z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#006ED3"
                    fillRule="evenodd"
                    d="M339.677 164.773c-1.318 8.72-1.865 19.113-1.114 32.701-17.596 3.016-33.268 2.405-43.775-.938-.812-11.996-1.057-67.502 14.948-71.289 5.782-1.62 10.274-1.473 16.407-.85 6.21.634 10.236 2.094 13.48 5.291 10.87 10.19 14.741 45.505 16.526 60.347.26 2.174-4.826 5.551-7.42 3.273-.836-1.007-1.914-3.018-2.78-5.924-2.404-8.057-5.039-20.519-6.272-22.613"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#065FB2"
                    fillRule="evenodd"
                    d="M355.658 186.06c.271 2.105.421 3.392.676 5.523.26 2.174-5.166 4.956-7.76 2.679-.843-1.015-1.553-2.885-2.651-6.208 2.094-1.827 6.452-2.329 9.735-1.994zM339.71 164.835c-1.369 8.714-1.475 21.699-1.147 32.639-16.044 2.751-30.919 2.751-41.975-.451 7.072.819 34.086 1.244 36.195-5.525 1.205-3.872 1.478-23.561 3.673-34.862 1.55 1.7 2.269 5.51 3.254 8.199z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M309.996 115.907c1.238 2.46 2.571 4.256 4.503 5.18l-.067 3.576-.521.443c-2.037 2.181.462 5.198 2.785 5.059 9.132.45 6.409-3.668 7.933-6.195l-.885-5.309c.302-.018 2.847-1.345 3.502-1.546.658-.201 1.62-1.465 1.684-2.8.065-1.334-1.998-.901-1.998-.901 2.501-3.418 5.33-6.208 1.661-9.283-3.67-3.079-12.101-4.871-12.101-4.871l-5.075-1.757c.647 1.749-.217 7.583-1.519 11.225-1.168 3.269-1.563 3.877.098 7.179z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E98385"
                    fillRule="evenodd"
                    d="M321.692 119.289s-2.871 4.918-5.228 5.363c-2.357.446-1.152.116-2.032.013l.067-3.575c1.625.26 3.214.131 7.193-1.798"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#12496F"
                    fillRule="evenodd"
                    d="M331.05 105.346c.859-2.784-.054-5.713-2.803-7.859-5.697-4.445-10.589-1.988-12.701-1.47-1.46.358-2.448.32-3.884.618-3.59.75-3.897 8.209-1.308 10.661.258.245.655-3.173.947-2.954.302.265.637.507 1.006.721 4.219 2.159 9.077-.402 11.535 2.488 1.215 1.432 1.094 3.496-.271 6.682-.399.94-.936 1.631.65.896.73-.337 1.932-.808 2.342-.476.91.737.451 2.285-.276 2.782-.325.221-.941.554-.941.554 6.705-1.27 8.273-10.657 5.704-12.643z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    d="M349.624 193.002c-.059 2.635-.242 3.789-.418 6.349-.038.567.89 1.628 1.63 2.344h5.64s.016-.013.021-.02c.784-.551 1.147-2.677 1.093-3.426.049-.028-.1-.487-.317-1.085-.382-1.053-.975-2.532-1.078-2.828-.247-.695-.699-1.319-.554-2.068-1.906-.6-4.56-.16-6.017.734z"
                ></path>
                <path
                    fill="#CE5600"
                    fillRule="evenodd"
                    d="M349.146 197.332h2.912c1.256.409.766 1.916 0 1.831h-1.013v2.532h-1.899v-4.363z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F1841A"
                    fillRule="evenodd"
                    d="M335.453 201.319h7.396v38.316h-7.396v-38.316z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#065FB2"
                    fillRule="evenodd"
                    d="M316.835 129.219c1.084 1.26 2.992 3.269 4.075 4.526 2.85-2.638 4.338-5.167 5.823-8.851-.526-.533-1.583-1.123-2.109-1.656-1.202 2.318-3.417 4.392-7.789 5.981z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#C3DCFA"
                    d="M307.967 172.514l3.894 7.485 5.222-7.581c-.866-10.46-.087-25.709.023-38.66l1.795-2.066-2.073-2.488-2.435 1.718 1.045 2.795c-3.574 8.806-6.875 33.107-7.471 38.792"
                ></path>
                <path
                    fill="#065FB2"
                    fillRule="evenodd"
                    d="M316.835 129.219c-1.22 1.092-3.216 2.437-4.433 3.529-.451-2.48-.149-5.185.653-8.044l1.338-.724c.098 2.006 1.246 3.704 2.442 5.237"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#0F4884"
                    fillRule="evenodd"
                    d="M333.89 286.123h-3.118a.782.782 0 00-.779.778v4.003c0 .428.351.778.779.778h3.118c.428 0 .779-.35.779-.778v-4.003a.782.782 0 00-.779-.778zM293.037 286.695c-3.499 1.453-9.361 3.913-11.77 5.062 1.259.971 2.46.778 2.46.778s6.832-2.128 8.774-3.506c1.147-.814.918-1.723.536-2.334z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E0ECF8"
                    fillRule="evenodd"
                    d="M332.152 149.54l-10.128-.891-.278 2.011 10.124.897.282-2.017z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#B7D5FF"
                    fillRule="evenodd"
                    d="M234.775 296.973c-1.133-.724-7.394-4.456-8.49-5.239-24.998-17.83-40.794-50.788-38.333-81.317l.606-7.526 7.466-1.175c13.101-2.058 22.728-6.007 34.237-12.49l4.715-2.656 4.711 2.656c11.572 6.517 21.171 10.316 34.323 12.505l7.386 1.229.601 7.457c2.571 31.871-14.756 66.387-41.678 83.578l-5.544 2.978z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#CBE0FF"
                    fillRule="evenodd"
                    d="M234.976 186.57l4.711 2.656c11.572 6.517 21.171 10.316 34.323 12.505l7.386 1.229.601 7.457c2.571 31.871-14.757 66.387-41.678 83.578l-5.343 2.872V186.57z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1A47B6"
                    fillRule="evenodd"
                    d="M200.307 213.668c-.557 10.932 1.604 21.969 5.676 32.1 5.808 14.443 15.946 28.051 28.993 36.78 13.044-8.729 23.181-22.337 28.989-36.78 4.075-10.136 6.239-21.179 5.676-32.116-5.98-1.084-11.801-2.57-17.528-4.677-5.953-2.192-11.603-4.972-17.137-8.04-5.813 3.223-11.753 6.136-18.022 8.39-5.441 1.958-10.971 3.354-16.647 4.343z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1A47B6"
                    fillRule="evenodd"
                    d="M200.307 213.668c-.557 10.932 1.604 21.969 5.676 32.1 5.808 14.443 15.946 28.051 28.993 36.78v-81.613c-5.813 3.223-11.753 6.136-18.022 8.39-5.441 1.958-10.971 3.354-16.647 4.343z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#27AFA6"
                    fillRule="evenodd"
                    d="M234.976 216.125c-11.214 0-20.302 9.077-20.302 20.277 0 11.2 9.088 20.275 20.302 20.275 11.213 0 20.298-9.078 20.298-20.275s-9.088-20.277-20.298-20.277z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#4FCCC4"
                    fillRule="evenodd"
                    d="M234.975 216.125c-10.893 0-19.783 8.57-20.278 19.329.495 10.759 9.385 19.329 20.278 19.329 10.894 0 19.781-8.57 20.278-19.329-.497-10.759-9.384-19.329-20.278-19.329z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M231.716 238.293l9.867-9.15a2.49 2.49 0 013.51.129 2.481 2.481 0 01-.127 3.506l-11.76 10.903-.036.029a2.491 2.491 0 01-3.502-.232l-4.864-5.538a2.488 2.488 0 01.232-3.501 2.498 2.498 0 013.503.232l3.177 3.622z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M143.93 287.898l.66-6.924-7.012.168.067 7.928 6.285-1.172z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#355298"
                    fillRule="evenodd"
                    d="M144.272 287.313c1.981 3.527 5.212 6.901 2.69 9.897h-12.838a5.78 5.78 0 01-.062-.186c-.123-.409-.722-.582-.322-2.083.743-2.79 2.93-7.079 3.683-7.298 1.488-.438 3.605.796 6.849-.33z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1D3975"
                    fillRule="evenodd"
                    d="M147.919 294.879v.005c.031.937-.067 1.764-.957 2.326h-12.838c-.662-.379-.835-.642-.384-2.27l14.179-.061z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#355298"
                    fillRule="evenodd"
                    d="M165.43 191.93l-7.515 30.233-3.835 17.995-8.513 43.835s-1.8.814-4.681.649c-2.88-.165-4.332-.587-4.332-.587-.065-12.944-.39-30.346-.503-45.991-.098-13.761-.457-25.993-.508-36.428-.023-4.518 5.158-17.291 5.093-17.127l24.794 7.421z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M174.923 287.898l-.026-7.161-7.015.168.756 8.165 6.285-1.172z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#355298"
                    fillRule="evenodd"
                    d="M175.387 286.366s11.01 4.484 14.099 5.983c2.398 1.164 3.758 3.614 1.787 4.861h-24.002a5.78 5.78 0 01-.062-.186c-.356-1.174-1.289-9.569 1.207-9.381 1.55.116 3.502.492 6.971-1.277z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1D3975"
                    fillRule="evenodd"
                    d="M192.23 294.879v.005l.016.52c-.037.747-.338 1.404-.978 1.806h-24.11a2.493 2.493 0 01-.065-.186c-.069-.229-.239-1.422-.322-2.084l25.459-.061z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#355298"
                    fillRule="evenodd"
                    d="M175.511 190.305c2.164 30.737 2.558 58.894.08 93.665 0 0-3.366.719-6.246.554-2.878-.165-2.355-.495-2.355-.495l-6.739-47.72s-1.524-10.864-5.09-19.334c-2.577-6.115-5.736-7.877-5.269-14.983.482-7.36-.142-13.062.879-15.607 1.024-2.543 6.128-1.52 6.128-1.52l18.612 5.44z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#1D3975"
                    fillRule="evenodd"
                    d="M161.76 247.512l-1.509-10.775s-.83-5.971-2.7-12.531l1.426-10.214 2.966 25.609-.183 7.911z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#AEC2FD"
                    fillRule="evenodd"
                    d="M162.92 152.489c3.25 7.128 5.674 14.242 13.124 19.953 12.01 9.206 24.098 12.747 25.787 11.794 1.449-.816 5.467-3.598.475-6.926-3.286-2.648-16.095-13.572-19.409-15.823-1.176-.796-8.717-22.786-18.955-26.519-1.032-.664-4.044 12.975-1.022 17.519"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#9BB3FB"
                    fillRule="evenodd"
                    d="M166.407 160.165c2.179 4.423 4.887 8.634 9.638 12.277 5.882 4.51 13.111 8.204 18.87 10.584-1.886-1.819-4.49-2.867-11.595-9.351-7.105-6.483-12.276-14.172-12.276-14.172l-4.637.665v-.003z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#AEC2FD"
                    fillRule="evenodd"
                    d="M132.461 160.212c1.259 8.32 3.02 24.926 2.303 37.888 16.786 2.879 31.739 2.295 41.766-.892-1.718-13.366.959-59.272-14.311-62.884-5.516-1.545-9.802-1.406-15.654-.808-10.383 1.056-37.673 18.291-42.665 25.938-3.5 5.363-5.922 13.073-6.595 18.678-.25 2.071 4.604 5.296 7.077 3.122 7.829-2.919 27.555-20.151 28.079-21.042z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#9BB3FB"
                    fillRule="evenodd"
                    d="M132.543 160.773c1.308 8.315 2.533 26.892 2.221 37.326 15.306 2.625 29.5 2.628 40.048-.43-6.749.781-32.523 1.19-34.534-5.27-1.151-3.694-2.058-25.835-4.155-36.615-1.478 1.622-2.638 2.424-3.58 4.989z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M161.971 125.412c-1.181 2.347-2.452 4.06-4.296 4.943l.062 3.411.497.422c1.948 2.081-.572 4.057-2.787 3.926-8.712.43-5.981-2.599-7.435-5.008l.843-5.064c-.289-.015-2.716-1.283-3.342-1.473-.627-.194-1.545-1.399-1.607-2.671-.062-1.273 1.906-.861 1.906-.861-2.383-3.261-5.086-5.922-1.584-8.858 3.503-2.934 11.546-4.647 11.546-4.647l4.841-1.677c-.617 1.669.209 7.236 1.452 10.711 1.114 3.116 1.488 3.698-.096 6.849"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E98385"
                    fillRule="evenodd"
                    d="M150.812 128.64s2.739 4.69 4.988 5.115c2.249.425 1.099.111 1.939.011l-.064-3.411c-1.55.25-3.066.126-6.863-1.715z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M155.447 138.114c-1.034 1.2-2.855 3.116-3.889 4.319-2.718-2.516-4.137-4.93-5.555-8.443.5-.508 1.511-1.072 2.014-1.579 1.145 2.212 3.26 4.191 7.43 5.705M155.447 138.114c1.163 1.043 3.066 2.326 4.229 3.369.431-2.365.142-4.948-.624-7.674l-1.276-.69c-.093 1.914-1.187 3.534-2.329 4.997"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    d="M115.136 191.341l4.371 36.136-3.92 60.066c.132.177.261.355.39.528.1.136.201.268.301.399.243.317.48.616.717.896.109.127.214.25.325.371 1.767 1.945 4.052 2.414 5.921 2.87 1.447.353 2.564.525 3.546 2.46a1.102 1.102 0 01-1.013 1.589l-17.158-.536a1.654 1.654 0 01-1.606-1.818l1.145-7.483s0-.01.005-.031c-.418-14.605-3.724-36.005-1.014-53.065 0 0-3.337-18.499-3.27-19.275.065-.775 11.262-23.107 11.262-23.107M100.952 221.048c-.746 6.426 3.744 10.241 2.999 16.67-.01 16.094-11.3 34.638-15.12 50.889-.127.538.835 5.648.727 6.182-1.697 1.81-6.53 4.366-7.048-3.985 2.163-14.033 4.85-36.711 10.413-53.781l-3.512-17.021 11.541 1.046z"
                ></path>
                <path
                    fill="#355298"
                    fillRule="evenodd"
                    d="M124.923 186.874c1.743 10.229.794 36.384-1.13 56.599-13.936 3.575-34.444 2.604-39.568-1.718 3.42-26.367 5.16-51.7 10.62-56.365 5.787-6.862 28.391-5.339 30.078 1.484z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M99.25 144.669s.928 10.473 3.151 23.636c2.221 13.16-.663 18.443-.663 18.443l17.338-.412s-1.129-15.37-3.009-24.785a836.964 836.964 0 00-4.013-18.819l-4.608.703-7.94-.129-.256 1.363z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M110.615 149.311c4.645-1.144 4.327-3.238 4.327-3.238l-4.977-4.425-11.37.911-.684 4.449 1.594 2.434c3.824-.103 6.468 1.015 11.11-.129"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F49123"
                    d="M87.562 143.917c-3.278 13.356 10.344 30.665 2.282 50.278 4.398.61 11.219 2.336 16.49 1.545.632-3.312 2.759-9.002 1.364-25.096-1.392-16.094-8.252-27.706-8.252-27.706l1.297.242 6.048.129 3.509-.703s3.833 10.174 5.135 18.752c1.302 8.577 1.101 29.944 1.406 34.823 4.257-.304 11.808.613 10.47-3.359-.988-2.932-3.559-16.3-3.654-20.669-.173-7.83 1.949-11.305.616-15.854-1.47-5.026-5.498-13.384-8.01-14.407-11.858-3.32-23.819-5.247-28.7 2.025z"
                ></path>
                <path
                    fill="#EF7E05"
                    d="M118.581 193.533c1.256 4.324 10.28 2.635 8.73-.711-.753-1.626-2.298-10.337-2.997-14.536-.833 5.961-6.764 12.094-5.733 15.247zM88.452 160.987c2.597 6.676 4.876 15.378 3.213 30.459 1.457.201 10.594 4.049 12.162 4.204.936-3.382-5.441-5.747-8.717-10.857-.051-.711-.234-1.924-.296-2.64-.743-8.843-2.329-17.969-4.74-24.409l-1.622 3.245v-.002z"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M109.08 137.526l.062 3.241.472.401c1.847 1.979-.154 6.038-2.259 5.915a9.29 9.29 0 01-5.447-1.44c-2.212-1.427-3.329-3.591-2.36-5.023l.694-.773.153-4.585c-.274-.015-2.272-1.151-2.868-1.332-.593-.182-1.468-1.326-1.527-2.537-.057-1.208 1.813-.816 1.813-.816-2.267-3.096-4.83-5.626-1.506-8.413 3.327-2.79 10.968-4.415 10.968-4.415l4.598-1.592c-.585 1.584.199 6.872 1.38 10.175 1.676 4.693.748 8.848-4.173 11.199"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E98385"
                    fillRule="evenodd"
                    d="M102.563 135.896s2.6 4.456 4.738 4.858c2.135.404 1.044.105 1.841.01l-.062-3.24c-1.47.237-2.911.121-6.517-1.631"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F49123"
                    d="M89.06 142.686c-11.798 4.886-13.297 30.261-10.878 37.517 2.033 5.883 17.558 21.472 22.62 19.354 2.442-1.02 4.799-4.358 1.125-6.74-5.372-2.373-13.094-13.75-13.4-15.211-1.455-10.816 7.213-35.974.533-34.92z"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M102.375 194.632l2.087 1.149c.639.011 9.789-.417 8.34.807-.838.708-3.188 1.687-3.154 1.79.433 1.339 5.049 1.579 5.093 1.7.64 1.713-3.659.948-4.379.685-.188-.07 2.989 1.203 2.674 1.594-.608.76-2.842-.378-3.899-.499-.258-.029 2.669 1.079 2.419 1.602-.859.489-3.151-.672-3.786-.763-.152-.02 2.303 1.074 2.492 1.404.157.281-.318.567-.661.451-5.075-1.687-3.378-3.413-9.243-5.193-3.26-.989.14-5.461 2.017-4.727z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#355298"
                    fillRule="evenodd"
                    d="M103.291 125.015s6.602.222 9.062-1.908c5.158-4.428 2.019-14.994-11.172-11.081-10.493 3.114-5.359 13.407-19.017 17.67-5.06 1.579-4.456 4.111-.018 5.831-3.027 1.069-.1 4.032 2.703 3.676-5.341 4.273 6.223 7.601 14.697 1.417 4.877-3.57-3.693-14.489 3.745-15.602M122.713 292.05s5.975 1.654 5.707 3.493c-.544 3.715-21.052 1.262-21.16 1.126-.728-.902-.813-5.095.165-6.344.727-.93 6.671 4.597 15.288 1.725zM91.44 297.387c-.128 2.386-8.95 1.613-10.248.897-1.932-1.064.325-6.28 1.344-7.645 2.82 5.831 6.313 1.844 6.978 2.089.851 1.677 2.045 2.46 1.927 4.659z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F49123"
                    fillRule="evenodd"
                    d="M113.547 142.026c13.122-1.605 21.898 20.661 23.417 28.425 1.171 6.331-8.297 24.666-13.934 25.335-1.315.158-4.72-2.243-3.11-5.329 3.626-4.868 7.378-14.935 6.909-16.408-1.612-4.016-4.336-6.393-7.634-8.415-2.398-1.471-1.55-6.88-3.2-12.135-1.628-5.185-5.795-10.218-2.448-11.47"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#EF7E05"
                    fillRule="evenodd"
                    d="M126.455 173.15c-.341-.665-2.401-2.684-2.786-3.261.209-6.288 1.801-9.507.604-13.593-.918-3.137-2.834-7.573-4.753-10.713 2.246 3.037 4.75 8.614 5.844 12.349 1.209 4.132 1.271 8.784 1.091 15.218z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F4B1B1"
                    fillRule="evenodd"
                    d="M119.907 191.928l-1.326 1.605c-.516.213-8.092 2.794-6.522 3.325.908.306 3.134.35 3.139.446.077 1.228-4.119 5.808-4.116 5.921.028 1.597 2.862-1.154 3.365-1.599.132-.116-1.539 1.87-1.364 2.357.07.19.725.149 1.266-.356.666-.618 1.63-1.86 1.849-1.847.227.013-1.498 2.012-1.189 2.37.199.216.501.003.908-.397.671-.659 1.488-1.864 1.728-1.908.245-.047-1.189 1.823-1.158 2.05.044.301.377.188.511.077 3.582-2.998 1.934-4.943 6.13-8.268 2.334-1.847-1.831-5.159-3.221-3.776zM205.168 179.891c.97-.237 4.823-1.131 5.705-.793.451.172.35.314.229.543-.108.201-.508.518-.835.631-.681.232-1.576 1.188-1.968 1.26 1.712 3.737 7.136 5.278 5.924 7.078-.405.162-2.961.217-3.613.402-2.079.595-3.17.665-9.354-4.693-2.092-1.811-.16-3.761 1.516-4.76l2.396.332z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#355298"
                    fillRule="evenodd"
                    d="M160.197 114.089c-4.144 2.321-9.103-.062-11.455 2.923-1.164 1.479-.965 3.54.521 6.679.438.925 1 1.597-.617.923-.743-.312-1.962-.737-2.362-.392-.885.77-.366 2.305.382 2.777.335.211.961.52.961.52-10.999-.675-15.971-25.884 8.243-23.968.683.054.214-1.927.928-1.952 5.439-.204 10.269 8.173 3.399 12.487"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_8_1994">
                    <path fill="#fff" d="M0 0H416V299H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default WhoHomeWarranty;
