import React, { ReactElement } from "react";

export default function WhatIsHomeWarranty(): ReactElement {
    return (
        <section className="bg-[#F2F9FF] rounded-[20px] py-14 lg:mt-32 mt-20 text-center px-6 md:px-2">
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-left sm:text-center">
                What Is a Home Warranty?
            </h2>
            <p className="lg:text-lg text-base leading-8 mt-4 max-w-[907px] mx-auto text-left sm:text-center">
                A home warranty is a service plan that helps you pay for repairs
                or replacements of your house hold appliances and systems. It's
                like a subscription that you renew each year. However, the
                coverage and terms may differ based on your chosen plan.
            </p>
        </section>
    );
}
