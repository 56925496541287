import React from "react";

function WhatIsDifferentIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 466 300"
        >
            <path
                fill="#ECF5FF"
                fillRule="evenodd"
                d="M201.907 10.5c-21.739 8.674-42.405 27.252-58.68 44.569-33.895 36.065-38.955 43.647-80.769 73.924-24.37 17.649-43.585 32.571-53.054 52.533-26.593 56.069 5.082 116.632 71.46 116.632h269.821s31.738.769 54.9-29.199c78.496-101.578-51.702-319.113-203.678-258.46z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#8C9EF3"
                d="M203.335 73.639h-24.437v35.17h24.437v-35.17z"
            ></path>
            <path
                fill="#AEC2FD"
                d="M205.564 69.762H176.67v5.81h28.894v-5.81z"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M65.523 144.308c.594 4.611 1.39 9.571 3.436 11.916 2.616 2.429 1.748 6.806-1.667 8.01-2.491.879-6.376.981-6.85-.365-.475-1.346.623-2.958.583-3.162l-3.093.043 7.598-16.445-.007.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F7AAAC"
                fillRule="evenodd"
                d="M92.035 290.524l.562-5.908-5.979.142.059 6.766 5.358-1z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M92.327 290.025c1.69 3.009 4.444 5.89 2.295 8.447H83.678a3.84 3.84 0 01-.054-.157c-.106-.35-.617-.496-.274-1.78.635-2.382 2.499-6.04 3.14-6.229 1.27-.372 3.076.678 5.841-.281"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D3975"
                fillRule="evenodd"
                d="M95.439 296.48c.026.806-.058 1.51-.817 1.992H83.678c-.565-.325-.711-.551-.328-1.937l12.089-.055z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M110.366 208.621l-6.406 25.801-3.268 15.359-7.26 37.41s-1.535.696-3.99.554c-2.455-.142-3.695-.5-3.695-.5-.055-11.047-.332-25.899-.427-39.252-.084-11.744-.39-22.182-.434-31.089-.018-3.855 4.4-14.757 4.34-14.618l21.14 6.335z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F7AAAC"
                fillRule="evenodd"
                d="M118.461 290.524l-.022-6.112-5.979.142.642 6.97 5.359-1z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M118.855 289.215s9.385 3.826 12.019 5.106c2.047.992 3.203 3.086 1.525 4.151h-20.464a2.83 2.83 0 01-.055-.157c-.303-1.003-1.098-8.166 1.029-8.006 1.32.099 2.987.42 5.942-1.09"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D3975"
                fillRule="evenodd"
                d="M133.216 296.48l.015.449c-.033.635-.288 1.196-.836 1.543h-20.559c-.018-.048-.036-.102-.055-.157-.058-.197-.204-1.215-.277-1.78l21.709-.055h.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M118.964 207.231c1.842 26.232 2.182 50.264.066 79.941 0 0-2.871.613-5.326.475-2.455-.143-2.006-.423-2.006-.423l-5.746-40.726s-1.298-9.275-4.341-16.5c-2.196-5.22-4.888-6.722-4.49-12.788.412-6.28-.12-11.149.751-13.32.872-2.17 5.224-1.298 5.224-1.298l15.872 4.643-.004-.004z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1D3975"
                fillRule="evenodd"
                d="M107.24 256.054l-1.288-9.195s-.707-5.095-2.302-10.697l1.219-8.714 2.528 21.855-.157 6.751z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#AEC2FD"
                fillRule="evenodd"
                d="M108.228 174.957c2.773 6.083 4.837 12.156 11.188 17.029 10.24 7.86 20.545 10.88 21.986 10.066 1.237-.696 4.658-3.071.405-5.912-2.802-2.261-13.723-11.584-16.547-13.502-.999-.682-7.431-19.448-16.16-22.632-.879-.569-3.447 11.073-.872 14.951z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#9BB3FB"
                fillRule="evenodd"
                d="M111.201 181.507c1.861 3.775 4.166 7.368 8.215 10.479 5.016 3.851 11.181 7.003 16.087 9.034-1.608-1.55-3.826-2.447-9.885-7.98-6.059-5.533-10.466-12.095-10.466-12.095l-3.954.566.003-.004z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#AEC2FD"
                fillRule="evenodd"
                d="M82.26 181.547c1.072 7.101 2.57 21.275 1.962 32.337 14.314 2.458 27.06 1.959 35.607-.762-1.463-11.405.82-50.584-12.199-53.67-4.706-1.317-8.357-1.2-13.347-.689-8.854.9-32.12 15.61-36.377 22.139-2.984 4.577-5.049 11.157-5.621 15.938-.212 1.769 3.925 4.523 6.033 2.666 6.676-2.491 23.493-17.197 23.941-17.959z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#9BB3FB"
                fillRule="evenodd"
                d="M82.329 182.025c1.116 7.098 2.156 22.952 1.893 31.859 13.048 2.239 25.152 2.239 34.144-.368-5.753.667-25.141.105-26.856-5.406-.981-3.151-1.63-22.999-3.418-32.202-1.258 1.386-4.96 3.925-5.76 6.117"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F7AAAC"
                fillRule="evenodd"
                d="M107.419 151.847c-1.007 2.003-2.091 3.465-3.663 4.22l.055 2.911.423.361c1.66 1.776-.489 3.461-2.378 3.348-7.427.365-5.1-2.217-6.34-4.275l.718-4.322c-.248-.014-2.316-1.094-2.849-1.258-.532-.164-1.317-1.193-1.371-2.279-.051-1.087 1.627-.734 1.627-.734-2.032-2.782-4.338-5.055-1.35-7.56 2.984-2.506 9.842-3.969 9.842-3.969l4.126-1.429c-.526 1.422.178 6.175 1.236 9.14.949 2.662 1.27 3.155-.08 5.846"
                clipRule="evenodd"
            ></path>
            <path
                fill="#EA8287"
                fillRule="evenodd"
                d="M97.905 154.601s2.335 4.005 4.253 4.366c1.919.361.938.095 1.653.011l-.055-2.911c-1.32.212-2.615.11-5.851-1.466z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M101.856 162.683c-.88 1.025-2.434 2.663-3.316 3.688-2.317-2.148-3.528-4.209-4.739-7.207.427-.434 1.288-.916 1.718-1.35.978 1.89 2.78 3.575 6.337 4.869zM101.856 162.687c.992.89 2.615 1.984 3.604 2.874.368-2.02.12-4.223-.529-6.55l-1.091-.588c-.08 1.634-1.01 3.017-1.984 4.264z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F7AAAC"
                d="M67.485 208.114l3.728 30.841-3.345 51.263c.114.153.223.303.336.452.084.117.172.23.255.343a17.628 17.628 0 00.89 1.083c1.507 1.66 3.455 2.061 5.05 2.451 1.236.303 2.184.449 3.023 2.101a.938.938 0 01-.865 1.357l-14.627-.456a1.415 1.415 0 01-1.372-1.55l.978-6.386s0-.011.003-.026c-.354-12.463-3.173-30.732-.864-45.292 0 0-2.845-15.785-2.787-16.449.055-.66 9.601-19.721 9.601-19.721M55.393 233.47c-.635 5.485 3.192 8.742 2.557 14.228-.007 13.736-9.634 29.561-12.892 43.432-.11.459.712 4.821.62 5.277-1.444 1.547-5.566 3.728-6.008-3.403 1.843-11.977 4.133-31.33 8.88-45.901l-2.996-14.527 9.839.894z"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M75.832 204.303c1.485 8.731.678 31.053-.963 48.304-11.881 3.049-29.366 2.225-33.736-1.466 2.915-22.504 4.4-44.125 9.054-48.108 4.932-5.857 24.207-4.555 25.645 1.27z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M53.945 168.282s.791 8.939 2.684 20.173c1.894 11.234-.565 15.742-.565 15.742l14.785-.35s-.963-13.12-2.565-21.155c-1.601-8.035-3.421-16.059-3.421-16.059l-3.93.602-6.77-.109-.215 1.163-.003-.007z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F7AAAC"
                fillRule="evenodd"
                d="M63.633 172.243c3.958-.978 3.688-2.765 3.688-2.765L63.08 165.7l-9.696.777-.58 3.796 1.357 2.079c3.26-.087 5.515.868 9.473-.109z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F49123"
                d="M43.978 167.636c-2.794 11.398 8.821 26.173 1.948 42.911 3.75.521 9.565 1.991 14.06 1.316.54-2.826 2.349-7.681 1.163-21.42-1.19-13.736-7.037-23.645-7.037-23.645l1.106.207 5.154.11 2.991-.602s3.269 8.684 4.378 16.004c1.112 7.32.94 25.557 1.196 29.722 3.63-.259 10.068.525 8.93-2.867-.843-2.502-3.035-13.914-3.115-17.642-.15-6.685 1.66-9.65.525-13.531-1.255-4.289-4.687-11.423-6.829-12.299-10.111-2.834-20.307-4.479-24.47 1.729"
            ></path>
            <path
                fill="#EF7E05"
                d="M70.425 209.985c1.073 3.691 8.766 2.247 7.446-.605-.642-1.39-1.963-8.823-2.557-12.408-.712 5.087-5.768 10.321-4.888 13.013zM44.737 182.207c2.215 5.697 4.159 13.123 2.74 25.995 1.244.171 9.032 3.453 10.37 3.588.8-2.885-4.64-4.905-7.434-9.264-.043-.609-.2-1.641-.251-2.254-.635-7.546-1.988-15.337-4.042-20.833l-1.383 2.768z"
            ></path>
            <path
                fill="#F7AAAC"
                fillRule="evenodd"
                d="M62.324 162.184l.05 2.764.402.343c1.576 1.689-.131 5.154-1.926 5.048a7.918 7.918 0 01-4.644-1.229c-1.89-1.218-2.842-3.064-2.014-4.286l.591-.66.132-3.913c-.234-.015-1.937-.981-2.444-1.138-.507-.157-1.252-1.135-1.303-2.167-.05-1.032 1.544-.696 1.544-.696-1.93-2.645-4.119-4.8-1.285-7.182 2.835-2.382 9.35-3.768 9.35-3.768l3.921-1.36c-.5 1.353.168 5.865 1.175 8.684 1.43 4.005.638 7.55-3.557 9.56"
                clipRule="evenodd"
            ></path>
            <path
                fill="#EA8287"
                fillRule="evenodd"
                d="M56.768 160.794s2.218 3.804 4.038 4.147c1.82.343.89.091 1.569.007l-.051-2.764c-1.255.2-2.484.102-5.56-1.394"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F49123"
                d="M45.255 166.586c-10.06 4.173-11.337 25.826-9.276 32.02 1.733 5.022 14.97 18.323 19.286 16.518 2.083-.872 4.09-3.72.956-5.755-4.578-2.025-11.163-11.734-11.422-12.981-1.24-9.231 6.15-30.703.456-29.802z"
            ></path>
            <path
                fill="#F7AAAC"
                fillRule="evenodd"
                d="M56.608 210.922l1.78.982c.543.01 8.346-.358 7.11.689-.716.605-2.718 1.441-2.69 1.528.37 1.145 4.305 1.35 4.345 1.452.544 1.462-3.119.809-3.735.583-.16-.058 2.55 1.025 2.28 1.361-.518.649-2.422-.325-3.323-.427-.22-.026 2.276.923 2.064 1.368-.733.415-2.688-.573-3.228-.65-.131-.018 1.963.916 2.123 1.197.135.241-.27.481-.562.386-4.326-1.44-2.878-2.914-7.879-4.431-2.78-.846.117-4.661 1.718-4.034"
                clipRule="evenodd"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M57.388 151.508s5.629.19 7.726-1.627c4.4-3.778 1.722-12.794-9.524-9.457-8.948 2.659-4.567 11.442-16.211 15.082-4.316 1.345-3.801 3.508-.015 4.974-2.583.912-.087 3.44 2.302 3.137-4.556 3.647 5.308 6.485 12.53 1.207 4.159-3.049-3.148-12.364 3.192-13.316zM73.946 294.07s5.092 1.411 4.866 2.979c-.463 3.17-17.947 1.076-18.039.963-.62-.769-.693-4.347.143-5.416.62-.795 5.687 3.925 13.033 1.474M47.284 298.621c-.11 2.035-7.632 1.375-8.74.766-1.646-.908.277-5.358 1.145-6.525 2.404 4.979 5.384 1.572 5.95 1.784.725 1.429 1.743 2.097 1.64 3.975"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F49123"
                fillRule="evenodd"
                d="M66.136 166.024c11.188-1.367 18.666 17.635 19.965 24.258.999 5.406-7.074 21.053-11.882 21.625-1.12.131-4.023-1.915-2.652-4.548 3.09-4.158 6.29-12.747 5.892-14.006-1.376-3.428-3.696-5.456-6.508-7.181-2.046-1.255-1.32-5.872-2.729-10.358-1.39-4.428-4.939-8.721-2.086-9.79z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#EF7E05"
                fillRule="evenodd"
                d="M77.138 192.591c-.292-.565-2.047-2.29-2.375-2.783.179-5.365 1.536-8.115.514-11.602-.784-2.677-2.415-6.463-4.052-9.143 1.915 2.593 4.049 7.352 4.983 10.537 1.032 3.526 1.083 7.495.93 12.988"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F7AAAC"
                fillRule="evenodd"
                d="M71.556 208.617l-1.13 1.368c-.438.182-6.902 2.385-5.56 2.838.773.262 2.67.299 2.678.379.065 1.05-3.51 4.957-3.51 5.055.026 1.364 2.44-.985 2.871-1.364.113-.098-1.313 1.594-1.163 2.013.058.164.616.128 1.08-.302.565-.529 1.39-1.587 1.575-1.576.194.011-1.276 1.718-1.014 2.024.168.186.427 0 .773-.339.573-.562 1.27-1.59 1.474-1.63.208-.04-1.014 1.557-.988 1.75.036.256.32.161.434.066 3.053-2.56 1.649-4.22 5.227-7.057 1.988-1.58-1.561-4.403-2.743-3.225M144.248 198.343c.828-.204 4.111-.967 4.866-.675.383.146.299.266.197.463-.091.172-.434.445-.712.54-.58.201-1.342 1.014-1.678 1.076 1.46 3.192 6.085 4.505 5.053 6.04-.347.139-2.525.182-3.083.343-1.773.507-2.699.569-7.974-4.005-1.784-1.546-.135-3.209 1.295-4.063l2.043.285-.007-.004z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#355298"
                fillRule="evenodd"
                d="M105.905 142.182c-3.531 1.98-7.763-.051-9.766 2.495-.992 1.262-.82 3.02.446 5.7.372.788.853 1.365-.526.788-.635-.266-1.674-.627-2.013-.332-.756.661-.314 1.966.324 2.371.285.179.821.445.821.445-9.379-.576-13.618-22.092 7.026-20.454.583.048.182-1.645.791-1.667 4.637-.171 8.759 6.974 2.897 10.658"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F7A12E"
                fillRule="evenodd"
                d="M223.822 66.523h29.624l35.089 6.15 39.47-6.15h15.54l77.554 66.446H236.352c0 19.188-12.53-57.543-12.53-66.446z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#B7D0FF"
                d="M412.931 132.973H288.539v159.215h124.392V132.973z"
            ></path>
            <path
                fill="#FFB75C"
                fillRule="evenodd"
                d="M148.906 132.973l74.916-66.45 74.916 66.45H148.906z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#D8E4FB"
                fillRule="evenodd"
                d="M288.539 132.973v159.211h-129.43V132.973l64.713-57.401 64.717 57.401z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M245.009 220.449h-42.374v71.709h42.374v-71.709z"
            ></path>
            <path
                fill="#024BC8"
                d="M242.08 223.214h-36.516v68.944h36.516v-68.944z"
            ></path>
            <path
                fill="#fff"
                d="M245.009 151.563h-42.374v51.948h42.374v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M242.08 154.492h-36.516v46.09h36.516v-46.09z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M224.479 184.724l17.6-18.255v3.961l-17.6 18.255v-3.961zM224.479 192.059l17.6-18.255v7.28l-17.6 18.255v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#D8E4FB"
                d="M242.08 222.605h-36.516v69.553h36.516v-69.553z"
            ></path>
            <path
                fill="#8C9EF3"
                fillRule="evenodd"
                d="M238.771 260.522v31.662h-29.993v-66.326h29.993v34.664z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M205.564 184.724l17.601-18.255v3.961l-17.601 18.255v-3.961zM205.564 192.059l17.601-18.255v7.28l-17.601 18.255v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M325.656 151.563h-23.46v51.948h23.46v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M322.726 154.492h-17.601v46.09h17.601v-46.09z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M305.125 177.356l17.601-18.254v3.961l-17.601 18.254v-3.961zM305.125 184.691l17.601-18.255v7.28l-17.601 18.255v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M346.186 151.563h-23.459v51.948h23.459v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M343.257 154.492h-17.601v46.09h17.601v-46.09z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M325.656 177.356l17.601-18.254v3.961l-17.601 18.254v-3.961zM325.656 184.691l17.601-18.255v7.28l-17.601 18.255v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M325.656 220.449h-23.46v51.948h23.46v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M322.726 223.378h-17.601v46.091h17.601v-46.091z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M305.125 246.243l17.601-18.255v3.961l-17.601 18.255v-3.961zM305.125 253.577l17.601-18.254v7.28l-17.601 18.254v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M346.186 220.449h-23.459v51.948h23.459v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M343.257 223.378h-17.601v46.091h17.601v-46.091z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M325.656 246.243l17.601-18.255v3.961l-17.601 18.255v-3.961zM325.656 253.577l17.601-18.254v7.28l-17.601 18.254v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M381.618 151.563h-23.46v51.948h23.46v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M378.688 154.492h-17.601v46.09h17.601v-46.09z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M361.087 177.356l17.601-18.254v3.961l-17.601 18.254v-3.961zM361.087 184.691l17.601-18.255v7.28l-17.601 18.255v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M402.148 151.563h-23.46v51.948h23.46v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M399.219 154.492h-17.601v46.09h17.601v-46.09z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M381.618 177.356l17.601-18.254v3.961l-17.601 18.254v-3.961zM381.618 184.691l17.601-18.255v7.28l-17.601 18.255v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M381.618 220.449h-23.46v51.948h23.46v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M378.688 223.378h-17.601v46.091h17.601v-46.091z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M361.087 246.243l17.601-18.255v3.961l-17.601 18.255v-3.961zM361.087 253.577l17.601-18.254v7.28l-17.601 18.254v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M402.148 220.449h-23.46v51.948h23.46v-51.948z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M399.219 223.378h-17.601v46.091h17.601v-46.091z"
            ></path>
            <path
                fill="#A5C4FF"
                fillRule="evenodd"
                d="M381.618 246.243l17.601-18.255v3.961l-17.601 18.255v-3.961zM381.618 253.577l17.601-18.254v7.28l-17.601 18.254v-7.28z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M225.288 153.638h-2.929v47.484h2.929v-47.484z"
            ></path>
            <path
                fill="#8C9EF3"
                d="M420.519 292.184H152.605v7.196h267.914v-7.196z"
            ></path>
            <path
                fill="#0F4884"
                fillRule="evenodd"
                d="M460.444 298.355H11.148a.512.512 0 000 1.021h449.296a.512.512 0 000-1.021z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#8C9EF3"
                d="M302.196 151.563h-1.612v51.948h1.612v-51.948zM358.158 151.563h-1.612v51.948h1.612v-51.948zM302.196 220.449h-1.612v51.948h1.612v-51.948zM358.158 220.449h-1.612v51.948h1.612v-51.948zM246.621 220.449h-1.612v71.709h1.612v-71.709zM246.621 151.563h-1.612v51.948h1.612v-51.948z"
            ></path>
            <path
                fill="#788ADD"
                d="M422.861 292.184H296.112v6.171h126.749v-6.171z"
            ></path>
            <path
                fill="#7E91F1"
                fillRule="evenodd"
                d="M408.215 194.172c-1.291-.834-8.427-5.125-9.677-6.028-28.489-20.509-46.491-58.42-43.686-93.537l.692-8.656 8.508-1.351c14.929-2.37 25.901-6.913 39.018-14.368l5.372-3.054 5.372 3.054c13.186 7.496 24.127 11.865 39.115 14.383l8.417 1.415.684 8.575c2.927 36.66-16.818 76.364-47.501 96.137l-6.319 3.427.005.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#A7B4F9"
                fillRule="evenodd"
                d="M407.983 67.178l5.418 3.035c13.298 7.45 24.332 11.79 39.448 14.293l8.488 1.406.69 8.521c2.952 36.431-16.961 75.886-47.906 95.536l-6.141 3.283V67.178h.003z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#ECF5FF"
                fillRule="evenodd"
                d="M369.398 98.875c-.629 12.202 1.807 24.525 6.395 35.832 6.539 16.124 17.958 31.316 32.649 41.06 14.69-9.744 26.107-24.938 32.648-41.06 4.588-11.313 7.024-23.64 6.392-35.85-6.735-1.21-13.292-2.869-19.741-5.222-6.701-2.445-13.065-5.549-19.299-8.972-6.545 3.597-13.236 6.85-20.294 9.366-6.128 2.184-12.355 3.743-18.747 4.846"
                clipRule="evenodd"
            ></path>
            <path
                fill="#ECF5FF"
                fillRule="evenodd"
                d="M369.397 98.877c-.622 12.202 1.785 24.524 6.319 35.832 6.462 16.123 17.746 31.314 32.264 41.058V84.663c-6.467 3.597-13.079 6.849-20.054 9.365-6.056 2.185-12.21 3.743-18.526 4.847"
                clipRule="evenodd"
            ></path>
            <path
                fill="#DEF"
                fillRule="evenodd"
                d="M407.983 89.264v82.822c13.247-9.378 23.573-23.354 29.613-38.11 4.088-9.99 6.427-20.805 6.296-31.597-6.001-1.194-11.903-2.766-17.661-4.848-6.304-2.28-12.354-5.127-18.251-8.265"
                clipRule="evenodd"
            ></path>
            <path
                fill="#3EC0C8"
                fillRule="evenodd"
                d="M408.441 103.988c12.46 0 22.563 10.094 22.563 22.546 0 12.451-10.103 22.546-22.563 22.546-12.461 0-22.564-10.095-22.564-22.546 0-12.452 10.103-22.546 22.564-22.546z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M404.083 130.111l11.799-10.63c1.201-1.08 3.082-1.015 4.197.147a2.827 2.827 0 01-.152 4.076l-14.061 12.669-.044.035c-1.228 1.043-3.112.92-4.186-.27l-5.817-6.437a2.83 2.83 0 01.278-4.066c1.227-1.043 3.112-.92 4.187.27l3.799 4.206z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#2A4589"
                fillRule="evenodd"
                d="M253.45 66.523l28.001 7.116-4.166 1.995-5.027-1.587-8.372 3.297 9.033-1.335 3.56 3.079 6.967-1.197 8.11 2.393-.223 2.531-5.195-.63-3.027-.37-.752 2.197-.97 3.654 3.374-3.859 8.434 3.936 3.721-3.232 6.734.412-3.068 4.636 13.344 1.663 12.84.905-7.076 6.357 14.766 8.137-6.741-9.279 4.731-6.448-12.082-4.431 3.641-3.885 4.425-4.723 5.388.868 2.097-4.847-4.191 2.717-4.626-1.021-7.514 3.549-5.658-3.55 14.077-9.048-29.267 2.155-6.975 3.494-12.964-3.074-25.349-2.575z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#7BA9E3"
                fillRule="evenodd"
                d="M288.539 23.868c.773 0 1.408.635 1.408 1.408V46.56c0 .773-.635 1.408-1.408 1.408a1.413 1.413 0 01-1.408-1.408V25.276c0-.773.634-1.408 1.408-1.408zM260.757 31.363a1.41 1.41 0 011.922.514l10.645 18.434a1.411 1.411 0 01-.515 1.922 1.41 1.41 0 01-1.922-.514l-10.645-18.434a1.411 1.411 0 01.515-1.922zM316.317 31.363a1.41 1.41 0 00-1.922.514L303.75 50.311a1.411 1.411 0 00.515 1.922 1.41 1.41 0 001.922-.514l10.645-18.434a1.41 1.41 0 00-.515-1.922z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default WhatIsDifferentIcon;
